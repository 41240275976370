<template>
  <div>
    <v-tabs v-model="tabIndex" :vertical="vertical" @change="onChange">
      <v-tab v-for="tab in tabs" :key="tab.content" :class="{ 'justify-start': vertical }">{{
        tab.title
      }}</v-tab>
      <v-tabs-items v-model="tabIndex">
        <v-tab-item v-for="tab in tabs" :key="tab.content">
          <slot :name="tab.content" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AcTabs',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const tabIndex = ref(props.value)

    const onChange = val => {
      emit('input', val)
    }

    watch(
      () => props.value,
      val => (tabIndex.value = val),
    )

    return {
      tabIndex,
      onChange,
    }
  },
}
</script>

<style lang="scss" scoped></style>
